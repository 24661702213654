<template>
  <div class="convenience">
    <div class="search-container">
      <el-row style="line-height: 48px">
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="0px">
            <el-col :span="8">
              <el-form-item prop="searchValue">
                <div class="input-wrapper" v-if="false">
                  <el-input
                    class="inputSearch"
                    clearable
                    v-model="form.searchValue"
                    placeholder="设备名称"
                    @keyup.enter.native="searchData"
                  ></el-input>
                  <img
                    @click="searchData"
                    class="input-image"
                    src="../../../../assets/images/input_search.png"
                    alt=""
                    srcset=""
                  />
                </div>
              </el-form-item>
            </el-col>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
      >
        <el-table-column
          prop="deviceName"
          align="center"
          min-width="168"
          label="设备名称"
        >
        </el-table-column>
        <el-table-column
          prop="deviceCode"
          min-width="120"
          align="center"
          label="设备ID"
        >
        </el-table-column>
        <el-table-column
          prop="curTemperature"
          align="center"
          min-width="115"
          label="温度℃"
        ></el-table-column>
        <el-table-column
          prop="tel"
          min-width="168"
          align="center"
          label="手机号"
        ></el-table-column>
        <el-table-column
          prop="name"
          min-width="168"
          align="center"
          label="姓名"
        >
          <template slot-scope="scope">
            <div  class="wrap">
              <img class="imgSize" :src="scope.row.faceUrl" alt="" />
              <span class="nameCenter">{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          min-width="168"
          label="上报时间"
        ></el-table-column>
      </el-table>
    </div>
    <div class="page-content">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import IndexClass from "./indexClass";
import mixin from "@/mixins/index";
import apis from "@/apis";
import { mapState } from "vuex";

export default {
  mixins: [mixin],
  data() {
    return {
      form: new IndexClass("form"), // 实例化一个表单的变量
      tableData: [],
    };
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", "venueId"]),
  },
  methods: {
    // 查询
    // searchData() {
    //   const formData = {
    //     venueId: this.venueId,
    //     deviceType: "5",
    //     // searchValue: this.form.searchValue,
    //   };
    //   console.log(formData);
    //   this.$http.get(`${apis.getIotDeviceListByType}?venueId=${formData.venueId}&deviceType=${formData.deviceType}`).then((res) => {
    //       if (res.data.code === 0) {
    //         console.log("列表展示", res);
    //         this.tableData = res.data.data.iotDeviceInfoList;
    //         this.form.total = this.tableData && this.tableData.length;
    //       }
    //     });
    // },
    searchData() {
      // TODO 新增确定后的接口
      const formData = {
        pageNum: this.form.pageNum,
        pageSize: this.form.pageSize,
      }
       this.$http.post(apis.getIotTemperatureWarn, formData).then((res) => {
        if (res.data.code === 0) {
          console.log("获取数据列表", res.data)
          this.tableData = res.data.rows;
          this.form.total = res.data.total
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.scoped.less";
</style>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.less";
.convenience {
  .search-container {
    .input-wrapper {
      width: 280px;
      position: relative;
      .inputSearch {
        width: 300px;
      }
      .input-image {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
  .page-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
  }
  .el-tabs__active-bar {
    display: none;
  }
}
.wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  .imgSize {
    display: inline-block;
    width: 50px;
    height: 50px;
  }
  .nameCenter {
    display: inline-block;
    margin-left: 12px;
    text-align: center;
    color: #909399;
    font-size: 12px;
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
  }
}

</style>
